import React from 'react'
import { Box, Container, Typography, Hidden, Button, Slide } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import UfoIcon from '../../images/ufo-image.svg'
import { Link } from 'gatsby'

const useStyles = makeStyles(theme =>({
    svgContainer:{
        display: 'block',
        width: '100%',
        maxWidth: '500px',
        overflow: 'hidden',
        margin:'0 auto', 
        position:'absolute',
        boxSizing:'border-box',
        top:'50%',
        left:'50%',
        transform:'translate(-50%,-50%)'
    },
    textContainer:{
        textAlign:'center',
        position:'absolute',
        left:'50%',
        transform: 'translateX(-50%)',
        bottom:'50px',
        color: theme.palette.primary.main,
    },
    mainContainer:{
        position: 'absolute',
        top: '0',
        left: '0',
        width: '100%',
        height: '100%'
    },
    materialContainer:{
        width:'100%',
        height:'100%',
        position:'relative',
    },
    goBackButton:{
        margin:'2rem',
    }
  }),{index: 1})

const NotFoundMessage = () => {
    const classes = useStyles()
    return (
        <Box className={classes.mainContainer}>
            <Container className={classes.materialContainer}>
                <Link to="/" style={{ textDecoration:'none' }}> 
                    <Button 
                        variant="text" 
                        color="primary"
                        component="span"
                        className={classes.goBackButton}
                        id="notFoundHomepageLink"
                    >
                        Go back to Homepage
                    </Button>
                </Link>
                <Box className={classes.svgContainer}>
                    <UfoIcon />
                    <Box className={classes.textContainer}>
                        <Hidden smDown>
                            <Slide 
                                direction="up" 
                                in={true} 
                                mountOnEnter 
                                unmountOnExit
                                {...({ timeout: 1000 })}
                            >
                                <Box>
                                    <Typography variant="h3" component="h1">Error 404</Typography>
                                    <Typography variant="h6" component="span">Seems like something's gone wrong <span role="img" aria-label="sad face">🙁</span></Typography>
                                </Box>
                            </Slide>
                        </Hidden>
                        <Hidden mdUp>
                            <Slide 
                                direction="up" 
                                in={true} 
                                mountOnEnter 
                                unmountOnExit
                                {...({ timeout: 1000 })}
                            >
                                <Box>
                                    <Typography variant="h4" component="h1">Error 404</Typography>
                                    <Typography component="span">Seems like something's gone wrong</Typography>
                                </Box>
                            </Slide>
                        </Hidden>
                    </Box>
                </Box>
            </Container>
        </Box>
    )
}

export default NotFoundMessage
