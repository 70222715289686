import React from 'react'
import SEO from '../components/Seo'
import Layout from '../components/Layout'
import NotFoundMessage from '../components/404/NotFoundMessage'


const NotFoundPage = () => {

  return(
    <Layout>
      <SEO title="Error" />
      <NotFoundMessage/>
    </Layout>)
}

export default NotFoundPage
